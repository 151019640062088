@import url(https://fonts.googleapis.com/css?family=Heebo:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.App {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
}
.Container {
  padding: 40px 0px 20px 0px;
  width: 90%;
  -webkit-flex: 1 1;
          flex: 1 1;
  -ms-flex: inherit;
  -webkit-align-self: center;
          align-self: center;
}

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .Container {
    width: 100% !important;
    padding: 30px;
  }
}
@media (min-width: 1280px) {
  .Container {
    width: 90%;
  }
}
@media (max-width: 599px) {
  .Container {
    padding: 10px;
    -webkit-align-items: center;
            align-items: center;
    width: 100% !important;
  }
}

/**Avatar Css**/
@media only screen and (max-width: 599px) {
  .Avatar-img-view-profile {
    width: 42px !important;
    height: 42px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
  }
  .Avatar-img-edit-profile {
    width: 70px !important;
    height: 70px !important;
    font-size: 24px !important;
    font-weight: 500;
  }
}
@media (min-width: 1280px) {
  .Avatar-img-view-profile {
    width: 72px !important;
    height: 72px !important;
    font-size: 25px !important;
    font-weight: 500;
  }
  .Avatar-img-edit-profile {
    width: 150px !important;
    height: 150px !important;
    font-size: 50px !important;
    font-weight: 500;
  }
}
@media only screen and (min-width: 600px) and (max-width: 959px) {
  .Avatar-img-view-profile {
    width: 52px !important;
    height: 52px !important;
    font-size: 18px !important;
    font-weight: 500;
  }
  .Avatar-img-edit-profile {
    width: 120px !important;
    height: 120px !important;
    font-size: 42px !important;
    font-weight: 500;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1279px) {
  .Avatar-img-view-profile {
    width: 72px !important;
    height: 72px !important;
    font-size: 25px !important;
    font-weight: 500;
  }
  .Avatar-img-edit-profile {
    width: 150px !important;
    height: 150px !important;
    font-weight: 500;
    font-size: 50px !important;
  }
}

/*****Back Arrraw in the User Profile*****/
@media (min-width: 960px) {
  .User-Profile-Arrow-Container {
    top: 75px !important;
  }
  .Company-Back-Arrow-Container {
    cursor: pointer;
    top: 34px;
    margin-left: 16px;
    z-index: 100;
    position: absolute;
  }
}
@media only screen and (max-width: 959px) and (min-width: 600px) {
  .User-Profile-Arrow-Container {
    top: 60px !important;
  }
  .Company-Back-Arrow-Container {
    cursor: pointer;
    top: 15px;
    margin-left: 16px;
    z-index: 100;
    position: absolute;
  }
}

@media (max-width: 599px) {
  .User-Profile-Arrow-Container {
    top: 30px !important;
  }
  .Company-Back-Arrow-Container {
    cursor: pointer;
    top: 0px;
    margin-left: 6px;
    z-index: 100;
    position: absolute;
  }
}

@media only screen and (min-width: 960px) {
  .right-content-main {
    padding-left: 90px;
  }
  .Profile-Avatar {
    margin-left: 60px !important;
  }
}
@media (max-width: 959px) and (min-width: 600px) {
  .right-content-main {
    padding-left: 70px;
  }
  .Profile-Avatar {
    margin-left: 40px !important;
  }
}
@media (max-width: 599px) {
  .right-content-main {
    padding-left: 0px;
  }
  .Profile-Avatar {
    margin-left: 10px !important;
  }
  .Profile-Name-Container {
    margin: 20px 0;
  }
}
.Profile-Avatar {
  -webkit-align-self: center;
          align-self: center;
}
.Profile-Name-Label {
  -webkit-align-self: center;
          align-self: center;
  padding-left: 10px;
}
.Profile-Name-Container {
  margin: 20px 0;
  display: -webkit-flex;
  display: flex;
}

.Company-Form-Row {
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 72px;
}

.Page-Label {
  color: #70828e;
  position: relative;
  top: -22px;
}
@media (max-width: 599px) {
  .Page-Label {
    font-size: 10px !important;
    top: -10px;
  }
}
@media (min-width: 600px) {
  .Page-Label {
    font-size: 12px;
    top: -15px;
  }
}

.Company-Space {
  margin: 124px 0 0 0;
}

.Workonomy-Space {
  margin: 72px 0 0 0;
}

.clear {
  clear: both;
}

